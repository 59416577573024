'use client'; // Error components must be Client Components
import * as Sentry from '@sentry/nextjs';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { isNotFoundError } from 'next/dist/client/components/not-found';

export default function Error({
    error,
    reset,
}: {
    error: Error;
    reset: () => void;
}) {
    const router = useRouter();
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log('checking the type of error.');
        if (isNotFoundError(error)) {
            // eslint-disable-next-line no-console
            console.log('handled not-found...');
            router.push('/not-found');
        } else {
            if (
                error &&
                (error.toString().search('missing') !== -1 ||
                    error.toString().search('ChunkLoadError') !== -1)
            ) {
                if (window && window.location) {
                    if (Sentry) {
                        Sentry.addBreadcrumb({
                            message: 'SYLNDR_CHUNK_LOAD_ERROR',
                        });
                        Sentry.addBreadcrumb({
                            message: `RELOADING URL IS ${window.location.href}`,
                        });
                        Sentry.captureException(error);
                    }
                    window.location.reload();
                    return;
                }
            } else {
                // eslint-disable-next-line no-console
                console.log("can't find the error . redirecting to not found");
                // eslint-disable-next-line no-console
                console.log('error.message', error.message);
                // eslint-disable-next-line no-console
                console.log('error.message', error);
                if (Sentry) {
                    Sentry.addBreadcrumb({ message: 'SYLNDR_UNKOWN_ERROR' });
                    if (window && window.location) {
                        Sentry.addBreadcrumb({
                            message: `UNKOWN ERROR URL IS ${window?.location?.href}`,
                        });
                    }
                    Sentry.captureException(error);
                }
            }

            // router.push('/not-found');
        }
    }, []);

    return <></>;
}
